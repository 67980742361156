.events-block{
    padding: 60px 0 80px;
    .left-block{
        height: 100%;
        margin-right: 20px;
        h3{
            font-weight: 700;
            font-size: 22px;
            color: var(--mainColor);
            margin: 27px 0 12px;
        }
        p{
            font-weight: 400;
            font-size: 15px;
            color: var(--darkColor);
            margin-bottom: 30px;
        }

        img{
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    .events-wrapper{
        display: flex;
        flex-direction: column;
        height: 100%;
        h3{
            font-weight: 700;
            font-size: 22px;
            color: var(--mainColor);
            margin-bottom: 40px;
        }

        .link{
            font-size: 14px;
            color: var(--mainColor);
            font-weight: 700;
            margin-top: auto;
        }
    }
    .right-block{
        position: relative;
        overflow: hidden;
        .back-img { 
            img{
                position: absolute;
                width: 100%;
                height: 100%;
            }
        }

        h3{
            font-size: 20px;
            font-weight: 700;
            color: var(--mainFontColor);
            margin-top: 50px;
        }

        p{
            color: var(--eventBlockColor);
            margin-bottom: 50px;
        }
        .input{
            margin-bottom: 14px;
        }

        .my-button{
            width: 100%;
        }

        .right-block-content{
            display: flex;
            flex-direction: column;
            align-items: center;
            position: relative;
            padding: 60px 40px 40px;

            i{
                font-size: 78px;
                color: var(--accentColor);
            }
        }
    }
}

.event-card{
    display: flex;
    gap: 20px;
    margin-bottom: 25px;
    cursor: pointer;

    .date{
        height: fit-content;
        white-space: nowrap;
        span{
            font-weight: 700;
            font-size: 34px;
            color: var(--accentColor); 
            line-height: 30px;
        }
        p{
            font-weight: 700;
            font-size: 16px;
            color: var(--mainColor);
            text-transform: uppercase;
            margin: 0;
            white-space: nowrap;
        }
        border-bottom: 2px solid var(--accentColor);
    }

    h5{
        font-weight: 700;
        font-size: 18px;
        color: var(--mainColor);
    }

    &:hover{ 
        h5{
            font-weight: 700;
            font-size: 18px;
            color: var(--accentColor);
        } 
    }
}