.breadcrumbs{
    padding: 26px 0;
    .list{
        list-style: none;
        margin: 0;
        padding: 0;
        font-size: 16px;

            color: var(--accentColor);

            span{
                margin: 0 14px;
            }
            a{
                color: #A0A0A0;

                &:hover{
                    color: var(--accentColor);
                }
            }
        
    }
}