.page-container{
    padding: 60px 0;
}

.article-container{
    padding: 30px 0 80px;
    p{
        font-size: 16px;
        color: #6B6B6B;
    }
}