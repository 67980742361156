/*ABOUT*/
.pd-r-0{
    padding-right: 0;
}
.my-button{
    background: var(--buttonColor);
    border-radius: 2px;
    outline: none;
    border: none;
    color: var(--buttonFontColor);
    font-weight: 700;
    font-size: 15px;
    padding: 16px 30px;
    transition: .2s;
    width: fit-content;

    &:hover{
        background: var(--buttonHoverColor);
    }
}
.about{
    .about-img-1{
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
    .about-wrapper{
        position: relative;
    }
    .about-img{
        position: absolute;
        width: 100%;
        left: 0;
        padding-right: 12px;
        height: 100%;
    }

    .about-content{
        position: relative;
        z-index: 1;
        display: flex;
        flex-direction: column;
        height: 100%;
        justify-content: center;
        margin-left: 50px;
        margin-right: 20px;

        h3{
            font-weight: 700;
            font-size: 29px;
            color: #FFFFFF;
            margin-bottom: 10px;
        }

        .subtitle{
            font-weight: 500;
            font-size: 19px;
            color: var(--accentColor);
            margin-bottom: 20px;
        }

        .text{
            color: var(--mainFontColor);
            font-weight: 400;
            font-size: 18px;
        }
    }
}


/*ABOUT-US*/

.about-us{
    position: relative;
    padding: 60px 0 40px;
    .about-us-img{
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        object-fit: cover;
    }

    .about-us-wrapper{
        position: relative;
        z-index: 1;
        font-size: 18px;
        color: var(--mainFontColor);

        span{
            font-weight: 600;
        }
    }

    .about-us-right{
        h3{
            font-weight: 600;
            font-size: 31px;
            color: var(--accentColor);
            margin-top: 40px;
        }
        img{
            width: 240px;
        }
    }
}