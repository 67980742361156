.chess-component{
    padding: 0 0 60px;
    .chess-grid{
        img{
            width: 100%;
            object-fit: cover;
            height: 100%;
        }
    }

    .chess-content{
        display: flex;
        align-items: center;
        margin-top: 20px;
        margin-bottom: 20px;
    }

    .chess-item{
        display: grid;
        grid-template-columns: 1fr 1fr;
    }

    .chess-elem{
        max-width: 620px;
    }

    .margin-left{
        .chess-content{
            margin-left: calc((100vw - 1337px)/2);
        }
    }
    .margin-right{
        .chess-content{
            margin-left: 80px;
            margin-right: calc((100vw - 1337px)/2);
        }
    }
}

.main-title{
    color: var(--mainColor);
    font-weight: 600;
    font-size: 30px;
    padding: 0 0 12px 0;
    border-bottom: 4px solid var(--accentColor);
    max-width: fit-content;
}