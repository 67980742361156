.advantages{
    background-color: #fff;
    position: relative;
    z-index: 1;
    padding: 50px 60px 30px;
    box-shadow: 0px -6px 24px rgba(10, 10, 10, 0.09);
    border-radius: 3px;

    &-item{
        img{
            width: 40px;
            height: 40px;
            object-fit: contain;
            margin-right: 25px;
        }

        h4{
            font-weight: 600;
            font-size: 18px;
            color: var(--mainColor);
            margin-bottom: 8px;
        }
        p{
            font-weight: 500;
            font-size: 16px;
            color: var(--advantagesFontColor);
            margin: 0;
        }
    }
}

/*ADVANTAGES 2*/

.advantages-2{
    background-color: var(--advantages2Color);
    padding: 60px 0;

    .advantages2-item{
        display: flex;
        flex-direction: column;
        img{
            width: 60px;
            height: 60px;
            object-fit: contain;
            margin-bottom: 11px;
        }
        h5{
            color: var(--advantages2TitleColor);
            font-weight: 700;
            font-size: 21px;
            line-height: 1.6;
            margin-bottom: 11px;
        }

        p{
            color: var(--advantages2FontColor);
        }

        a{
            margin-top: auto;
            font-size: 14px;
            color: var(--accentColor);
            cursor: pointer;
        }
    }
}