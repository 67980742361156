@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:wght@400;700&display=swap');

:root {
    --mainColor: #093254;
    --accentColor:  #E3002B;
    --accentHoverColor: #ca0025;
    --footerBottomColor: #202020;
    --footerColor: #072239;
    --headerTopColor: #192f59;
    --headerMenuColor: #ffffff;
    --menuFontColor: #163269;
    --footerIconColor: var(--accentColor);
    --advantagesFontColor: #B7B7B7;
    --headerFontColor: #919bad;
    --buttonColor: var(--accentColor);
    --buttonFontColor: #fff;
    --buttonHoverColor: var(--accentHoverColor);
    --faqBackColor: #EFEFEF;
    --faqColor: #FFFFFF;
    --faqItemColor: #F7F7F7;
    --faqFontColor: #8D8D8D;
    --faqTitleColor: #8D8D8D;
    --faqTitleActive: #464646;
    --advantages2Color: var(--mainColor);
    --mainFontColor: #fff;
    --advantages2TitleColor: var(--mainFontColor);
    --advantages2FontColor: #D4E1FA;
    --stepsBackColor: var(--mainColor);
    --stepsTitleColor: var(--mainFontColor);
    --stepsFontColor: #D4E1F8;
    --darkColor:#747474;
    --partnersBackColor:var(--mainColor);
    --footerFontColor: #ffff;
    --eventBlockColor: #96aad3;
    --formColor:  #11426a;
    --formFontColor: #ffff;
    --fontFamily: "Montserrat", sans-serif;
}

@import "./css/combine/style";
@import "./css//include/css/page-builder";
@import "./css/style-core";
@import "./css/kingster-style-custom";


#root{
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}
.kingster-container{
    width: 100%;
    padding: 0 24px;
}
a{
    text-decoration: none;
}

body{
    font-family: var(--fontFamily);
    line-height: 1.7;
    font-size: 16px;
    padding: 0;
    margin: 0;
    overflow-x: hidden;
}

.kingster-navigation .sf-menu > li a{
    position: relative;
    &::after{
        content: "";
        position: absolute;
        border-bottom: 7px solid var(--accentColor);
        bottom: 0;
        left: 0;
        width: 0;
        transition: .3s;
    }

    &:hover{
        &::after{
            width: 100%;
        }
    }

    &:active, &.active{
        color: var(--accentColor);

        &::after{
            width: 100%;
        }
    }
}

.main-slider{
    .swiper-button-next, .swiper-button-prev{
        color: #fff;
    }
    &-item{
        img{
            position: absolute;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }

        .item-content{
            position: relative;
            z-index: 1;

            &-subtitle{
                font-size: 32px;
                color: #fff;
            }

            &-title{
                font-family: "Playfair Display";
                font-weight: 700;
                font-size: 62px;
                line-height: 100%;
                color: #fff;
                background-color: var(--mainColor);
                padding: 10px 20px 15px;
                width: fit-content;
                border-radius: 4px;
                margin: 15px 0 30px;

            }

            &-button{
                color: #2d2d2d; 
                background-color: var(--accentColor);
                padding: 12px 20px;
                width: fit-content;
                font-weight: 600;
                cursor: pointer;
                color: #fff;
                transition: .3s;

                &:hover{
                    background-color: var(--accentHoverColor);
                }
            }
        }
    }
}

.main-slider .main-slider-item{
    position: relative;
    width: 100%;
    height: 700px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.gdlr-core-wrapper-1{
    margin: -43px auto 0 auto;
    padding: 0px 0px 0px 0px;
    max-width: 1320px; 
}

.main-container{
    max-width: 1320px; 
    margin: 0 auto;
}

@import './css/about';
@import './css/advantages';
@import './css/news-blog';
@import './css/video';
@import './css/faq';
@import './css/steps';
@import './css/concats-form';
@import './css/footer';
@import './css/events-block';
@import './css/page-top';
@import './css/page-container';
@import './css/blog-card';
@import './css/breadcrumbs';
@import './css/shareBlock';
@import './css/languages';
@import './css/events';
@import './css/partners';
@import './css/chess-component';
@import './css/three-column';
@import './css/gallery';
@import './css/modals';
@import './css/workers';
@import './css/faq-sample';

.description{
    padding: 0 0 40px;
    font-size: 18px;

    img[style*="float: left"] {
        margin-right:20px;
    }
      
    img[style*="float: right"] {
        margin-left: 20px;
    }

    ul{
        margin-bottom: 20px;
    }
    li{
        margin-bottom: 10px;
    }
    p{
        margin: 0;
    }
}
img[style*="float: left"] {
    margin-right:20px;
}
  
img[style*="float: right"] {
    margin-left: 20px;
}
.text-component{
    padding: 40px 0;
    p{
        font-size: 18px;
    }
}
.kingster-navigation .sf-menu > .kingster-normal-menu li > a{
    display: flex;
    align-items: center;
}
.kingster-navigation .sf-menu > .kingster-normal-menu li > a.sf-with-ul-pre:after{
    width: auto;
}
.menu-item{
    .sib-menu{
        li a::after{
            border: none;
            width: auto;
            left: auto;
            bottom: auto;
        }
    }
    &:hover{
        ul{
            display: block;
            li a::after{
                border: none;
                width: auto;
                left: auto;
                bottom: auto;
            }
        }
    }
}

.kingster-logo{
    padding: 20px 20px 0;
    margin: 0;
    img{
        max-height: 59px;
        object-fit: contain;
    }
}

.offcanvas-body, .offcanvas-header{
    background-color: #1f1f1f;
}

.offcanvas-header{
    .close{
        line-height: 40px;
        font-size: 30px;
        display: block;
        text-align: left;
        color: #979797;
        cursor: pointer;
        &::after{
            content: "\4d";
            font-family: elegantIcons;
        }
    }
}
.kingster-mobile-button-hamburger{
    cursor: pointer;
}

.offcanvas-body{
    .main-ul{
        list-style: none;
        margin: 0;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        padding: 17px 40px 20px;
        li{
            padding: 17px 0;
            border-bottom: 1px solid #979797;

            .flex{
                display: flex;
                justify-content: space-between;
                align-items: center;
            }
            i{
                color: #979797;
                cursor: pointer;
                font-size: 13px;
            }
            a{
                color: #979797;
                font-size: 12px;
                text-transform: uppercase;
                font-weight: 700;

                &:hover{
                    color: #ffff;
                }
            }

            .sub-menu{
                display: none;

                li{
                    border: none;
                    padding: 5px 0;
                }
            }

            .sub-menu-visible{
                display: block;
            }
        }
    }
}

@media(max-width: 1360px){
    .main-slider{
        &-item{
            .item-content{
                &-subtitle{
                    font-size: 24px;
                }
    
                &-title{
                    font-size: 50px
                }
            }
        }
    }
    .about-us {
        .main-container{
            margin: 0 20px;
        }
    }
    .events-block, .contact-form, .footer, .partners, .faq, .advantages-2, .three-column, .sample-page, .breadcrumbs-wrapper, .page-container, .page-top, .events, .article-top, .article-container{
        .main-container{
            margin: 0 20px;
        } 
    }
    .new-blog-left{
        padding: 100px 20px;
    }
    .chess-component .margin-left .chess-content{
        margin-left: 20px;
        margin-right: 40px;
    }
    .chess-component .margin-right .chess-content{
        margin-right: 20px;
    }
}

.pagination {
    margin-bottom: 0;
    margin-top: 30px;
    display: flex;
    justify-content: center;
  
      li {
          font-weight: 500;
          border-radius: 2px;
          border: none;
          line-height: 1;
          margin: 0 2px;
          padding: 9px 10px 8px;
          height: 33px;
          background: transparent;
          color: #3d464d;
          cursor: pointer;
          -webkit-user-select: none;
              -moz-user-select: none;
              -ms-user-select: none;
                  user-select: none;
      }
  
      li:hover,
      li:focus {
          -webkit-box-shadow: none;
                  box-shadow: none;
          background: #ebebeb;
          color: #3d464d;
      }
  
      li:first-child {
      border-radius: 2px;
      }
      li:first-child  {
      margin-left: 0;
      }
     li:last-child{
      border-radius: 2px;
      }
      li:last-child {
      margin-right: 0;
      }
  
      li.active  {
      background: var(--mainColor);
      color: #fff;
      cursor: default;
      z-index: 2;
      }
  
      li.disabled {
      background: transparent;
      color: #b3b3b3;
      }
  }
.header-sticky{
    position: fixed;
    top: 0;
    z-index: 10;
    width: 100%;

    .kingster-navigation{
        padding-top: 14px;

        .sf-menu{
            li{
                a{
                    padding-bottom: 25px;
                }
                .sub-menu{
                    li{
                        a{
                            padding-bottom: 10px;
                        }
                    }
                }
            }
        }
    }
    .kingster-logo{
        padding: 10px 0 10px;
        img{
            height: 46px;
        }
    }
}
.worker-item{
    h4{
        margin-bottom: 0;
    }
    .position{
        color: var(--accentColor);
    }
}
@media(max-width: 1320px){
    .new-blog .news-back-img{
        right: 0;
    }
    .advantages-2 .advantages2-item h5{
        font-size: 18px;
    }
    .advantages-2 .advantages2-item p{
        font-size: 15px;
    }
    .blog-card{
        height: 300px;
    }
}

@media(max-width: 1200px){
    .about .about-content{
        padding: 30px;
        margin: 0;
    }
    .about-us .about-us-wrapper{
        font-size: 16px;
    }
    .faq .nav .nav-link{
        padding: 10px 15px;
        font-size: 15px;
    }
    .blog-card{
        height: 280px;
    }
    .event-card2 .title{
        gap: 10px;
    }
    .event-card2 h3{
        font-size: 17px;
    }
    .event-card2 .date{
        font-size: 14px;
        span{
            font-size: 26px;
        }
    }
    .event-card2 .block{
        height: 220px;
    }
}

@media(max-width: 1024px){
    .about .about-content h3{
        font-size: 24px;
    }
    .about-us .about-us-right h3{
        font-size: 24px;
    }
    .about{
        overflow: hidden;
    }
    .about .about-content .subtitle{
        font-size: 16px;
    }
    .about .about-content .text{
        font-size: 16px;
    }

    .blog-card{
        margin-bottom: 30px;
    }
    .blog-card .blog-card-content h3{
        font-size: 18px;
    }
    .event-card2 .block{
        height: 260px;
    }
    .article-top{
        height: 450px;
    }
    .article-top .page-top-content h1{
        font-size: 28px;
    }
}

@media(max-width: 992px){
    .advantages{
        .advantages-item{
            padding: 30px 0;
        }
    }
    .advantages-2{
        .advantages2-item{
            padding: 20px;
        }
    }
    .main-slider .main-slider-item{
        height: 500px;
    }
    .new-blog .news-back-img{
        width: 100%;
    }
    .new-blog .quick-links{
        padding: 105px 50px 37px 50px
    }

    .events-block {
        .row{
            display: grid;
            grid-template-columns: 1fr 1fr;

            .col-4{
                width: 100%;
            }

            .events-main{
                grid-column: 1/3;
                grid-row: 2;
                margin-top: 30px;
            }
        }
    }

    .about-us{
        padding: 100px 0;
    }
    .video{
        height: 450px;
    }
    .faq .faq-wrapper{
        grid-template-columns: 1fr;

        .faq-left{
            display: none;
        }
        .faq-content .pane-img{
            display: none;
        }
    }
    .partners img{
        height: 80px;
        width: 100px;
    }
    .main-slider-item .item-content-title{
        font-size: 36px;
        max-width: 600px;
    }

}

@media(max-width: 768px){
    .steps-wrapper{
        grid-template-columns: 1fr 1fr;

        .steps-item{
            &:last-child{
                grid-column: 1/3;
            }
        }
    }
    .main-slider-item .item-content-subtitle{
        font-size: 18px;
    }
}

@media(max-width: 767px){
    .advantages{
        padding: 30px 20px;
    }
    .about .about-content{
        padding: 50px 20px;
    }
    .new-blog .news-grid{
        grid-template-columns: 1fr;
    }
    .new-blog .news-sub-grid{
        gap: 20px;
    }
    .video{
        height: 300px;
    }
    #h3_1dd7_23{
        font-size: 24px;
        margin: 10px 0;
    }
    .video .video-content .center i{
        font-size: 40px;
    }
    .faq .faq-content{
        height: auto;
    }
    .faq .tab-content{
        padding: 30px 20px;
    }
    .description{
        img{
            width: 100%;
        }
    }
    .main-slider .swiper-button-next, .main-slider .swiper-button-prev{
        display: none;
    }
    .footer{
        .row{
            .col-sm-12{
                margin-bottom: 50px;
            }
        }
        .footer-left{
            margin-right: 26px;
        }
    }
    .main-slider-item .item-content-title{
        font-size: 26px;
        max-width: 300px;
    }
    .chess-component .chess-item{
        grid-template-columns: 1fr;
    }
    .three-column .ml-3{
        margin: 20px 0 0;
    }
    .chess-component .margin-right .chess-content{
        margin: 30px 20px 0;
    }
    .chess-component .margin-left .chess-content{
        margin: 0 20px 0;
    }
    .chess-component .chess-grid img{
        display: none;
    }
    .article-top{
        height: 280px;
    }
    .sample-page .page-top-content .page-title p{
        font-size: 16px;
    }
    .article-top .page-top-content h1{
        font-size: 26px;
    }
    .article-top .page-top-content .page-title{
        margin: auto 0 40px;
    }
    .footer-flex{
        flex-direction: column;
    }
    .page-top{
        padding: 80px 0;
    }
    .page-top .page-top-content .subtitle{
        font-size: 16px;
    }
    .page-top .page-top-content h1 {
        font-size: 26px;
    }
    .footer .footer-left img{
        width: 110px;
    }
}

@media(max-width: 480px){
    .steps-wrapper{
        grid-template-columns: 1fr;
        .steps-item{
            &:last-child{
                grid-column: 1;
            }
        }
    }
    .events-block .row{
        display: flex;
    }
    .events-block .row .events-main{
        margin-bottom: 30px;
    }
    .event-card2 .block{
        height: 220px;
    }
    .article-top{
        height: 350px;
    }
    .article-top .page-top-content h1{
        font-size: 26px;
    }
    .article-top .page-top-content .page-title{
        flex-direction: column;
        gap: 0;
        align-items: start;
    }
    .article-top .page-top-content .date{
        border: none;
    }
    .workers .workers-slider .worker-slide img{
        width: 100%;
        height: 300px;
    }
}

.kingster-navigation .sf-menu > li:last-child{
    .sub-menu{
        right: 0;
    }
}



/*.preloader {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #fff;
    z-index: 9999;
}*/
.s2 {
    position: absolute;
    height: 100px;
    width: 100px;
    background-color: transparent;
    top: 50vh;
    left: 50%;
    transform: translate(-50%, -50%);
}
.s1 {
    position: absolute;
    height: 100px;
    width: 100px;
    top: 50vh;
    left: 50%;
    transform-origin: center;
    transform: translate(-50%, -50%) rotate(45deg);
    background-color: transparent;
}
.bigcon {
    position: absolute;
    height: 95px;
    width: 95px;
    top: 50vh;
    left: 50%;
    transform-origin: center;
    transform: translate(-50%, -50%) rotate(-45deg);
    background-color: transparent;
    animation: bigcon 2s infinite linear;
    animation-delay: 0.25s;
}
.b {
    border-radius: 50%;
    position: absolute;
}
.s {
    width: 25px;
    height: 25px;
    animation: small 2s infinite ease;
    box-shadow: 0px 2px rgba(0, 0, 0, 0.3);
    background-color: var(--mainColor);
}
.s:nth-child(1) {
    top: 0%;
    left: 0%;
}
.s:nth-child(2) {
    top: 0%;
    right: 0%;
}
.s:nth-child(3) {
    right: 0%;
    bottom: 0%;
}
.s:nth-child(4) {
    bottom: 0%;
    left: 0%;
}
.big {
    width: 20px;
    height: 20px;
    border-radius: 15px;
    box-shadow: 0px 0px 10px #cfcece, 0px 0px 20px #cfcece, 0px 0px 30px #cfcece, 0px 0px 50px #cfcece, 0px 0px 60px #cfcece;
    z-index: 1;
    background-color: #cfcece;
    animation: bigball 1s infinite linear;
}
.sb1 {
    animation-delay: -1.75s;
}
.sb6 {
    animation-delay: -1.5s;
}
.sb2 {
    animation-delay: -1.25s;
}
.sb7 {
    animation-delay: -1s;
}
.sb3 {
    animation-delay: -0.75s;
}
.sb8 {
    animation-delay: -0.5s;
}
.sb4 {
    animation-delay: -0.25s;
}
.sb5 {
    animation-delay: -0s;
}
@keyframes bigcon {
    0% {
        transform-origin: center;
        transform: translate(-50%, -50%) rotate(45deg);
    }
    100% {
        transform-origin: center;
        transform: translate(-50%, -50%) rotate(405deg);
    }
}
@keyframes small {
    0% {
        transform: scale(1);
        background-color: #cfcece;
    }
    10% {
        transform: scale(1.3);
        background-color: #cfcece;
    }
    15% {
        transform: scale(1);
    }
    25% {
        transform: scale(1);
        background-color: #cfcece;
    }
    100% {
        transform: scale(1);
        background-color: #cfcece;
    }
}




.preloader{
    position: fixed;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.432);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10;
}

.isLoading {
    pointer-events: none;
    //opacity: .5;
    position: relative;
    min-height: 100px;
  
    &:before {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      margin-top: -20px;
      margin-left: -20px;
      width: 40px;
      height: 40px;
      border-radius: 50%;
      border: 3px solid var(--mainColor);
      border-left-color: transparent;
      animation: rotate .5s infinite linear;
      z-index: 99;
    }
  
    @keyframes rotate {
      100% {
        transform: rotate(360deg);
      }
    }
  }

.article-container{
    .gallery .gallery-item img{
        height: 300px;
    }
}

@media (max-width: 1260px){
    .article-container{
        .gallery .gallery-item img{
            height: 240px;
        }
    }
}

@media(max-width: 767px){
    .application-modal form{
        padding: 20px 0;
    }
    .application-modal .modal-top{
        padding: 0;
    }
}

.modal-max-width{
    .b24-form-btn{
        background-color: var(--accentColor);
    }
    .b24-form-header-padding{
        display: none;
    }
    .b24-form-padding-side{
        padding: 0;
    }
    .b24-form-sign{
        display: none;
    }
    .b24-form-content form{
        padding: 0 20px;
    }
    .b24-form-wrapper.b24-form-shadow{
        box-shadow: none;
    }
    .modal-dialog{
       max-width: 600px !important;
    }
    .b24-form-wrapper.b24-form-border-bottom{
        border: none;
    }
    .b24-form-progress-bar{
        stroke: var(--accentColor);
    }
}

.modal-max-width{
    .b24-form-btn:hover{
        background-color: var(--accentHoverColor);
    }
}

#bitdatamodal{
    .b24-form-header{
        display: none;
    }
    .b24-form-wrapper{
        box-shadow: none;
        border: none;
    }
    .b24-form-sign{
        display: none;
    }
    .b24-form-padding-side{
        padding: 0;
    }
}

#sampleformbitr{
    .b24-form-sign{
        display: none;
    }
    .b24-form-wrapper.b24-form-border-bottom{
        border: none;
    }
}

#biteventform{
    .b24-form-sign{
        display: none;
    }
    .b24-form-wrapper.b24-form-border-bottom{
        border: none;
    }
}
.video-img{
    img{
        height: 470px;
        object-fit: cover;
        width: 100%;
        filter: brightness(70%);
    }
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    span{
        position: absolute;
        font-size: 50px;
        z-index: 10;
        color: #fff;
        cursor: pointer;
        transition: .3s;
    }
    &:hover{
        span{
            transform: scale(1.1);
        }
    }
}
.video-img2{
    img{
        height: 340px;
        object-fit: cover;
        width: 100%;
        filter: brightness(70%);
    }
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    span{
        position: absolute;
        font-size: 50px;
        z-index: 10;
        color: #fff;
        cursor: pointer;
        transition: .3s;
    }
    &:hover{
        span{
            transform: scale(1.1);
        }
    }
}

@media(max-width: 1024px){
    .shorts-slider{
        padding: 0 20px !important;
    }
}