.faq-sample{
    padding: 40px 0 60px;
    .accordion-item{
        //padding: 25px 25px 25px 30px;
        //background-color: #f3f3f3;
        padding-bottom: 20px;
        .accordion-button {
            position: relative;
            border-radius: 5px;
            padding: 25px 25px 25px 30px;
            background-color: #f3f3f3;
            color: var(--accentColor);
            transition: .3s;
            i {
                font-size: 25.5px;
                display: inline-flex;
                align-items: center;
                justify-content: center;
                color: var(--accentColor);
                transition: .5s ease-in-out;
                position: absolute;
                right: 20px;
            }
            &::after {
                display: none;
            }
            .fa-minus-circle {
                opacity: 0;
            }
            &:not(.collapsed) {
                background-color: var(--accentColor);
                color: #ffff;
                
        
                i {
                    color: #ffff;
                    transform: rotateY(180deg);
                }
                .fa-minus-circle {
                    opacity: 1;
                }
            }
        }

        .accordion-body{
            margin-top: 20px;
        }
    }
}