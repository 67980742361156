.share-box{
    display: flex;
    justify-content: center;
    margin: 50px 0 20px;

    ul{
        list-style: none;
        display: flex;
        margin: 0 0 0 20px;
        gap: 15px;
    }

    .tag-area{
        text-transform: uppercase;
        font-size: 15px;
        border-right: 1px solid #e6e6e6;
        padding-right: 20px;
    }
}