.three-column{
    padding: 40px 0;
    p{
        color: #6B6B6B;
        margin: 0;
    }

    .mr-3{
        margin-right: 15px;
    }
    .ml-3{
        margin-left: 15px;
    }
}