.blog-card{
    position: relative;
    height: 376px;
    margin-bottom: 20px;

    img{
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
    
    .blog-card-content{
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        transition: .3s;
        opacity: 0;
        visibility: hidden;
        background: #0000006b;
        color: #FFFFFF;
        display: flex;
        flex-direction: column;
        padding: 0px 45px 60px 60px;
        justify-content: end;

        h3{
            font-weight: 700;
            font-size: 22px;
            margin-bottom: 15px;
            a{
                color: #FFFFFF;

                &:hover{
                    color: var(--accentColor);
                }
            }
        }
    }

    &:hover{
        .blog-card-content{
            visibility: visible;
            opacity: 1;
        }
    }
}