.application-modal{
    position: relative;
    overflow: hidden;
    .modal-top{
        padding: 0 40px;
        text-align: center;
    }
    form{
        padding: 20px 40px 40px;
        .modal-flex{
            display: flex;
            flex-direction: column;

            input{
                background-color: transparent;
                border-bottom:1px solid var(--mainColor);
                margin-bottom: 20px;
                color: #333333;
                height: 48px;

                &::placeholder{
                    color: #333333;
                }
            }

            .flex{
                display: flex;
                justify-content: end;
                margin-top: 30px;
            }
        }
    }

    .modal-success{
        position: absolute;
        top: 0;
        background-color: #fff;
        width: 100%;
        height: 100%;
        padding: 0 40px 40px;
        text-align: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        transition: .3s;
        transform: translateX(-100%);
    }

    .modal-success-visible{
        transform: translate(0);
    }
}
.modal-header{
    border: none;
}