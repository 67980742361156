.video{
    position: relative;
    height: 670px;

    .video-back{
        position: absolute;
        width: 100%;
        left: 0;
        height: 100%;

        img{
            width: 100%;
            height: 100%;
            object-fit: cover;
        }

        video{
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
    .main-container{
        height: 100%;
    }

    .video-content{
        display: flex;
        align-items: center;
        justify-content: center;
        justify-content: center;
        position: relative;
        z-index: 1;
        color: #fff;
        height: 100%;

        .center{
            max-width: 670px;
            display: flex;
            flex-direction: column;
            align-items: center;
            text-align: center;

            i{
                font-size: 78px;
                transition: .2s;
                cursor: pointer;

                &:hover{
                    transform: scale(1.1);
                }
            }

            h3{
                margin: 36px 0 26px;
            }
        }
    }
}

.video-modal{
    iframe{
        width: 100%;
        height: 600px;
    }
}