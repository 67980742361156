.workers{
    padding: 40px 0 60px;

    h3{
        margin-bottom: 36px;
    }
    .workers-slider{
        .worker-slide{
            img{
                width: 330px;
                height: 248px;
                object-fit: cover;
            }

            h5{
                font-size: 20px;
                margin-bottom: 0;
            }
            p{}
        }
    }
    .swiper-button-next, .swiper-button-prev{
        color: #ffff;
    }
}