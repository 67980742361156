.events{
    padding: 60px 0;
}
.event-card2{
    cursor: pointer;
    margin-bottom: 20px;
    .block{
        overflow: hidden;
        height: 256px;
    }

    &:hover{
        img{
            transform: scale(1.1) rotate(2deg);
        }

        a{
            color: var(--accentColor);
        }
    }
    img{
        height: 100%;
        object-fit: cover;
        width: 100%;
        transition: .5s ease-in-out;
    }
    .title{
        display: flex;
        gap: 25px;
        margin-top: 20px;
    }
    h3{
        font-size: 19px;
        a{
            color: #182847;

        }
    }

    .date{
        color: #152647;
        font-size: 16px;
        font-weight: 700;
        text-transform: uppercase;
        border-bottom: 2px solid var(--accentColor);
        height: fit-content;

        span{
            font-size: 34px;
            color: var(--accentColor);
            line-height: 1;
        }

        p{
            margin: 0;
            white-space: nowrap;
        }
    }
}