.new-blog{
    position: relative;
    overflow: hidden;
    &-left{
        padding: 100px 0;
        h3{
            margin-bottom: 8px;
        }
    }

    .news-grid{
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 40px;
        margin-top: 30px;
    }

    .news-card{
        margin-bottom: 15px;
        h5{
            color: #444444;
            font-weight: 700;
            font-size: 19px;
            margin-bottom: 0;
        }
        .news-date{
            color: #959595;
            font-weight: 600;
            font-size: 13px;
            text-transform: uppercase;
        }

        img{
            height: 223px;
            width: 100%;
            object-fit: cover;
        }

        &:hover{
            cursor: pointer;
            img{
                opacity: .6;
            }
            h5{
                color: var(--accentColor);
            }
        }
    }

    .news-sub-grid{
        display: grid;
        grid-template-columns: 1fr;

        .news-card{
            display: flex;
            gap: 20px;

            h5{
                font-size: 16px;
            }
            .news-date{
                font-size: 11px;
                margin-bottom: 4px;
            }
            img{
                width: 80px;
                height: 80px;
                border-radius: 3px;
                object-fit: cover;
            }
        }
    }

    .news-relative{
        position: relative;
    }
    .news-back-img{
        position: absolute;
        height: 100%;
        top: 0;
        right: calc((-100vw + 1320px) /2);

        .red-button{
            width: 100%;
            display: flex;
            gap: 15px;
            align-items: center;
            cursor: pointer;
            position: relative;
            z-index: 2;

            img{
                width: 42px;
                height: 39px;
            }
        }
        img{
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
    .quick-links{
        position: relative;
        z-index: 1;
        padding: 105px 0 37px 50px;

        h3{
            font-weight: 700;
            font-size: 25px;
            color: #ffff;
        }
        .list{
            display: flex;
            flex-direction: column;

            a{
                font-weight: 600;
                font-size: 16px;
                color: #ffff;
                border-bottom: 1px solid #53607A;
                padding:15px 0 18px;

                &:hover{
                    color: var(--accentColor);
                }
            }
        }
    }
}