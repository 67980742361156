.page-top{
    position: relative;
    padding: 120px 0;
    background: linear-gradient(0deg, rgba(9, 50, 84, 0.85), rgba(9, 50, 84, 0.85));

    .back-img{
        position: absolute;
        width: 100%;
        top: 0;
        left: 0;
        height: 100%;

        img{
            width: 100%;
            height: 100%;
            object-fit: cover;
            position: relative;
            z-index: -1;
        }
    }

    .page-top-content{
        position: relative;

        h1{
            font-weight: 700;
            font-size: 48px;
            color: #FFFFFF;   
            margin: 0;
        }

        .subtitle{
            font-weight: 400;
            font-size: 21px;
            color: var(--accentColor);
        }
    }
}

.article-top{
    position: relative;
    height: 570px;
    
    .back-linear{
        position: absolute;
        right: 0px;
        bottom: 0px;
        left: 0px;
        height: 333px;
        background: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 1));
    }

    .back-img{
        position: absolute;
        width: 100%;
        top: 0;
        left: 0;
        height: 100%;

        img{
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    .main-container{
        height: 100%;
    }

    .page-top-content{
        position: relative;
        height: 100%;
        display: flex;
        z-index: 1;

        .page-title{
            display: flex;
            gap: 30px;
            align-items: center;
            margin: auto 0 80px;
        }
        h1{
            font-weight: 700;
            font-size: 48px;
            color: #FFFFFF;   
            margin: 0;
        }

        .date{
            font-weight: 400;
            font-size: 14px;
            text-transform: uppercase;
            color: #B1B1B1;
            border-right: 1px solid #fff;
            padding-right: 20px;
            white-space: nowrap;

            span{
                font-weight: 700;
                font-size: 27px;
                color: #FFFFFF;
            }

            p{
                margin: 0;
            }
        }
    }
}

.sample-page{
    .page-top-content{
        .page-title{
            flex-direction: column;
            align-items: start;
            gap: 6px;
            p{
                margin: 0;
                font-size: 21px;
                color: var(--accentColor);
            }
        }
    }
}