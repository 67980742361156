.footer{
    padding: 70px 0 0;
    background-color: var(--footerColor);
    margin-top: auto;

    .footer-left{
        img{
            margin-bottom: 30px;
            max-width: 150px;
        }
        a{
            color: var(--mainFontColor);
        }
    }

    .footer-item{
        h4{
            font-weight: 700;
            font-size: 17px;
            color: var(--mainFontColor);
            border-bottom: 2px solid var(--accentColor);
            padding-bottom: 15px;
            margin-bottom: 26px;
            margin-right: 26px;
            width: fit-content;
        }

        ul{
            padding: 0;
            margin: 0;
            li{
                list-style: none;
                padding: 0;
                margin-bottom: 20px;
                display: flex;
                color: var(--footerFontColor);

                i{
                    color: var(--accentColor);
                    margin-right: 8px;
                    font-size: 18px;
                    margin-top: 5px;
                }
                a{
                    font-weight: 400;
                    font-size: 14px;
                    color: var(--footerFontColor);

                    &:hover{
                        color: var(--accentColor);
                    }
                }
                span{
                    font-size: 14px;
                }
            }
        }
    }

    .footer-bottom{
        margin-top: 70px;
        color: #838383;
        background-color: var(--footerBottomColor);
        padding: 22px 0;

        .footer-flex{
            display: flex;
            justify-content: space-between;

            .flex{
                display: flex;
                gap: 25px;

                a{
                    color: var(--footerIconColor);
                }
            }
        }
    }
}