.kingster-top-bar-right{
    display: flex;

    .dropdown{
        .btn-primary{
            background-color: transparent;
            border: none;
            padding: 0 0 0 20px;
            margin: 0;
        }
    }
}