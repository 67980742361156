.gallery{
    padding: 40px 0 60px;

    .swiper-button-next, .swiper-button-prev{
        color: #ffff;
    }
    .gallery-grid{
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
    }
    .gallery-item{
        .gallery-image{
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            span{
                position: absolute;
                color:#ffff;
                font-size: 22px;
                opacity: 0;
            }
        }
        img{
            width: 100%;
            height: 350px;
            object-fit: cover;
            transition: .3s;
        }

        &:hover{
            img{
                filter: brightness(60%);
            }
            .gallery-image{
                span{
                    opacity: 1;
                }
            }
        }
    }
    .main-title{
        margin-bottom: 40px;
    }
}

@media(max-width: 1440px){
    .gallery{
            .gallery-item{

                img{
                    height: 280px;
                }
    
                
            }
        
    
    }
}
@media(max-width: 1260px){
    .gallery{
            .gallery-item{

                img{
                    height: 240px;
                }
    
                
            }
        
    
    }
}
@media(max-width: 1024px){
    .gallery{
        .gallery-grid{
            grid-template-columns: 1fr 1fr 1fr;
        }
        .gallery-item{

            img{
                height: 260px;
            }

            
        }
    }
}
@media(max-width: 768px){
    .gallery{
        .gallery-grid{
            grid-template-columns: 1fr 1fr;
        }
        .gallery-item{

            img{
                height: 240px;
            }

            
        }
        .gallery-slide{
            img{
                height: 260px;
            }
        }
    }
}
@media(max-width: 480px){
    .gallery{
            .gallery-item{
                img{
                    height: 180px;
                }
            }
            .gallery-slide{
                img{
                    height: 250px;
                }
            }
    }
}